<template>
  <div class="failure">
    <GlobalInfoBar
      v-if="calssSelect == '01'"
      title="预估价管理"
      content="提供展示快捷选项"
    />
    <GlobalInfoBar
      v-if="calssSelect == '02'"
      title="价格管理"
      content="管理三秒估价和确认交易时用户成交价"
    />
    <GlobalInfoBar
      v-if="calssSelect == '03'"
      title="价格管理"
      content="管理SHS订单预估价"
    />
    <div class="class_type">
      <el-radio-group v-model="calssSelect">
        <el-radio-button label="01">三秒估价-预估价</el-radio-button>
        <el-radio-button label="02">确认交易-用户成交价</el-radio-button>
        <el-radio-button label="03">SHS订单预估价设置</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="calssSelect == '01'" class="case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_hsfl_.png" alt />
        <div class="case-title_max">初始预估价设置</div>
      </div>
      <div class="styk_numj">
        <div class="class_type">
          <div>初始预估价设置：</div>
          门店预估价=原始预估价 ×
          <div class="input_box" style="margin: 0 10px">
            <el-input
              :maxlength="5"
              v-model.trim="faultTypeList.factorRatio"
              size="small"
            ></el-input>
          </div>
        </div>
        <p>(说明：店员侧查看的预估价会按照商户返利、红包进行扣减)</p>
      </div>
      <div class="styk_numj">
        <div class="class_type">
          <div>估价是否启用评估靓机小花等成色标识：</div>
          <div class="input_box" style="margin: 0 10px">
            <el-switch v-model="faultTypeList.useMachineHealth"></el-switch>
          </div>
        </div>
        <p style="padding-left: 44px">
          (说明：启用后，店员根据模板选择功能项，系统自动标识靓机，小花，大花，外爆，内爆5种成色标识)
        </p>
      </div>
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_hsfl_.png" alt />
        <div class="case-title_max">显示预估价范围设置</div>
      </div>
      <div class="case-title2">
        <div class="case-title2_min">
          左区间=预估价×左区间下调比例，下限限制。右区间=预估价×右区间上调比例，上限限制。
        </div>
      </div>
      <div class="tb_action">
        <el-table border :data="faultTypeList.regionViews">
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column
            align="center"
            label="(初始扣减后)预估价所属价位段"
            prop="region"
          >
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="预估价范围左区间值下调比例"
            prop="leftSideRatio"
          >
            <template slot-scope="{ row }">
              <span>{{ row.leftSideRatio }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="最多下调"
            prop="leftSideLimit"
          />
          <el-table-column
            align="center"
            label="预估价范围右区间值上调比例"
            prop="rightSideRatio"
          >
            <template slot-scope="{ row }">
              <span>{{ row.rightSideRatio }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            label="最多上调"
            prop="rightSideLimit"
            align="center"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                size="small"
                @click.native.prevent="dblclick(scope.row)"
                type="primary"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 40px 40px">
        <el-button type="primary" @click="usedDialogVisible = true"
          >保存</el-button
        >
      </div>
    </div>
    <div v-if="calssSelect == '02'" class="case">
      <Transaction />
    </div>
    <div v-if="calssSelect == '03'" class="case">
      <div class="case-title2">
        <div class="case-title2_min">
          说明：该配置适用于闪回渠道的订单（包含荣耀、三星柜机、闪回KA订单）的预估价获取，预估价=分毫靓机价*旧机所属机况系数
        </div>
      </div>
      <div class="tb_action">
        <el-table border :data="shsDataList">
          <el-table-column type="index" width="100" align="center" label="序号">
          </el-table-column>
          <el-table-column align="center" label="分毫靓机价" prop="region">
            <template slot-scope="{ row }">
              <span>{{ row.lower }}＜区间≤{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="靓机" prop="niceFactor">
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.niceFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="小花" prop="littleSnowFactor">
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.littleSnowFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="大花" prop="bigSnowFactor">
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.bigSnowFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="外爆+功能正常"
            prop="outBurstNormalFactor"
          >
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.outBurstNormalFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="外爆+功能异常"
            prop="outBurstAbnormalFactor"
          >
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.outBurstAbnormalFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="内爆+功能正常"
            prop="innerBurstNormalFactor"
          >
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.innerBurstNormalFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="内爆+功能异常"
            prop="innerBurstAbnormalFactor"
          >
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.innerBurstAbnormalFactor"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="其他(正常开机和无法标记机况)"
            prop="otherNormalStart"
          >
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.otherNormalStart"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="其他(不能开机)"
            prop="otherUnStart"
          >
            <template slot-scope="{ row }">
              <el-input
                :disabled="row.isEitd"
                oninput="this.value=this.value.replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                v-model="row.otherUnStart"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row, $index }">
              <el-button
                size="small"
                v-if="row.isEitd"
                @click.native.prevent="saveSHSclick(row, 'action', $index)"
                type="primary"
                >修改</el-button
              >
              <el-button
                size="small"
                v-else
                plain
                @click.native.prevent="saveSHSclick(row, 'save', $index)"
                type="primary"
                >保存</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 40px 40px" v-if="calssSelect !== '03'">
        <el-button type="primary" @click="usedDialogVisible = true"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog
      title="操作提示"
      :visible.sync="ahsvisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <p style="color: #ff8080; margin-bottom: 10px">保存后配置将立即生效</p>
      <div style="text-align: center; margin-bottom: 20px">
        是否确认保存<span style="color: #0981ff"
          >{{ shsData.lower }}-{{ shsData.upper }}</span
        >的配置
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="ahsvisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="Shsconfrim"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="usedDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div style="font-size: 16px; color: #666666; line-height: 50px">
        <div style="text-align: center">是否保存预估价设置？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="usedDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="saveconfig">确定</el-button>
      </span>
    </el-dialog>
    <!-- 预估价范围设置 -->
    <el-dialog
      title="预估价范围设置"
      :visible.sync="editPriceShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="editPriceClosed"
    >
      <el-form
        :model="editPriceForm"
        :rules="editPricerules"
        ref="editPriceRulesForm"
        label-width="85px"
      >
        <el-form-item label="(初始扣减后)预估价所处范围:" label-width="195px">
          <el-col :span="11">
            <el-form-item>
              <el-input
                size="small"
                placeholder="最低价"
                disabled
                v-model.trim="editPriceForm.lower"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item>
              <el-input
                size="small"
                maxlength="5"
                disabled
                placeholder="最高价"
                v-model.trim="editPriceForm.upper"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <div>
          <div style="margin-bottom: 20px">预估价范围左区间值:</div>
          <el-form-item
            label="下调比例:"
            prop="leftSideRatio"
            label-width="110px"
          >
            <el-input
              size="small"
              min="0"
              max="100"
              placeholder="输入下调比例"
              v-model.trim="editPriceForm.leftSideRatio"
              @input="limitInput($event, 'leftSideRatio')"
              @blur="inputBlur('leftSideRatio')"
            >
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="最多下调:"
            prop="leftSideLimit"
            label-width="110px"
          >
            <el-input
              size="small"
              placeholder="输入最多下调"
              maxlength="6"
              v-model.trim="editPriceForm.leftSideLimit"
            >
            </el-input>
          </el-form-item>
        </div>
        <div>
          <div style="margin-bottom: 20px">预估价范围右区间值:</div>
          <el-form-item
            label="上调比例:"
            prop="rightSideRatio"
            label-width="110px"
          >
            <el-input
              size="small"
              min="0"
              max="100"
              placeholder="输入上调比例"
              v-model.trim="editPriceForm.rightSideRatio"
              @input="limitInput($event, 'rightSideRatio')"
              @blur="inputBlur('rightSideRatio')"
            >
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="最多上调:"
            prop="rightSideLimit"
            label-width="110px"
          >
            <el-input
              size="small"
              placeholder="输入最多上调"
              maxlength="6"
              v-model.trim="editPriceForm.rightSideLimit"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitEditPrice()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import _api from "@/utils/request";
import Transaction from "./valuation/Transaction.vue";
let checkNum = (rule, value, callback) => {
  if (value < 0 || value > 100) {
    callback(new Error("上调或者下调比例必须大于等于0小于等于100"));
  } else {
    callback();
  }
};
export default {
  name: "failure",
  components: { Transaction },
  data() {
    return {
      ahsvisible: false,
      shsData: {},
      shsIndex: null,
      calssSelect: "01",
      coefficient: 1, //预估价系数
      usedType: false,
      usedDialogVisible: false,
      editPriceShow: false, // 弹框的出现与否
      faultTypeList: [],
      shsDataList: [],
      editPricerules: {
        leftSideRatio: [
          { required: true, message: "请输入下调比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        leftSideLimit: [
          {
            required: true,
            message: "请输入最多下调",
            trigger: "blur",
          },
        ],
        rightSideRatio: [
          { required: true, message: "请输入上调比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        rightSideLimit: [
          {
            required: true,
            message: "请输入最多上调",
            trigger: "blur",
          },
        ],
      },
      editPriceForm: {
        createTime: "",
        id: "",
        leftSideLimit: "",
        leftSideRatio: "",
        lower: "",
        region: "",
        rightSideLimit: "",
        rightSideRatio: "",
        updateTime: "",
        upper: "",
      },
      command: "",
    };
  },
  watch:{
    calssSelect(val){
      console.log(val);
      if(val=='03'){
        this.getSHSConfig()
      }
    }
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    getSHSConfig() {
      _api.getSHSedConfig().then((res) => {
        if (res.code === 1) {
          this.shsDataList = res.data.map((item) => {
            return {
              isEitd: true, //是否为修改状态
              ...item,
            };
          });
        }
      });
    },
    saveSHSclick(row, type, index) {
      console.log(row, type, index);
      this.shsIndex=index
      if (type !== "save") {
        row.isEitd = !row.isEitd;
      } else {
        this.shsData = row;
        this.ahsvisible = true;
      }
    },
    //保存SHS配置
    Shsconfrim() {
      _api.saveSHSConfig(this.shsData).then((res) => {
        this.$message({
          showClose: true,
          message: "保存成功！",
          type: "success",
        });
        this.shsDataList[this.shsIndex].isEitd=true
        this.ahsvisible = false;
      });
    },
    //关闭编辑
    editPriceClosed() {
      this.$refs["editPriceRulesForm"].resetFields();
    },
    //比例
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      // this.editPriceForm[name] =value.replace(/[^0-9]/g,'')
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
      if (this.editPriceForm[name] >= 100) {
        this.editPriceForm[name] = "99";
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (
        this.editPriceForm[name].toString().indexOf(".") ==
        this.editPriceForm[name].length - 1
      ) {
        this.editPriceForm[name] = this.editPriceForm[name].replace(".", "");
      }
    },
    // 编辑弹框确认
    submitEditPrice() {
      console.log("opopopopop", this.editPriceForm);
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          //修改后赋值修改项
          let copydata = JSON.parse(JSON.stringify(this.faultTypeList));
          copydata.regionViews.forEach((item, index) => {
            if (item.id == this.editPriceForm.id) {
              copydata.regionViews[index] = JSON.parse(
                JSON.stringify(this.editPriceForm)
              );
            }
          });
          this.faultTypeList.regionViews = copydata.regionViews;
          this.editPriceShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭弹窗
    handleDialogClosed() {
      this.usedDialogVisible = false;
      this.command = "";
      // this.handleCurrentChange();
    },
    handleCurrentChange() {
      _api.getEstimatedConfig().then((res) => {
        if (res.code === 1) {
          this.faultTypeList = res.data;
        }
      });
    },
    //预估价范围设置
    dblclick(row) {
      console.log(row);
      this.editPriceShow = true;
      this.editPriceForm = JSON.parse(JSON.stringify(row));
    },
    // 保存
    saveconfig() {
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      _api
        .saveEstimatedConfig({
          command: this.command,
          ...this.faultTypeList,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "预估价相关设置成功！",
              type: "success",
            });
            this.command = "";
            this.handleCurrentChange();
            this.usedDialogVisible = false;
          }
        });
    },
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.failure {
  /deep/.el-input-group__append {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }

  .tb_action {
    padding: 10px 20px;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .command .el-input {
    width: 80%;
  }

  .case {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    width: 100%;
    padding: 14px 0;

    .styk_numj {
      display: flex;
      align-items: center;
      margin-left: 40px;

      > p {
        padding-left: 20px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
      }
    }

    .class_type {
      padding: 10px 20px;
      display: flex;
      align-items: center;

      .input_box {
        width: 80px;
      }

      .tip {
        font-size: 14px;
        color: #666666;
      }

      /deep/.el-radio-group {
        .el-radio-button {
          margin-right: 20px;

          .el-radio-button__inner {
            border-radius: 4px;
            border: 1px solid #0981ff;
          }
        }
      }
    }

    .case-title {
      padding: 0 20px;
      display: flex;
      align-items: center;

      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }

      .case-title_max {
        font-size: 18px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: bold;
        color: #333333;
        line-height: 41px;
      }

      .case-title_min {
        margin-left: 5px;
        width: 500px;
        height: 13px;
        font-size: 12px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
        line-height: 45px;
      }
    }

    .case-img {
      margin: 20px;
      height: 280px;
      background: #f5f6fa;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .upload_Add {
        margin-bottom: 50px;
      }

      .upload_Class {
        position: relative;
        width: 200px;
        height: 200px;

        .img:hover .mark {
          position: absolute;
          top: 0;
          left: 0;
          width: 148px;
          height: 148px;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }

        .img {
          position: relative;
          padding: 0;
          width: 148px;
          height: 148px;
          border-radius: 12px;
          margin: 0 auto;

          img {
            width: 148px;
            height: 148px;
          }

          .mark {
            display: none;
          }
        }

        .sort {
          width: 120px;
          height: 30px;
          margin: 20px auto;
        }
      }
    }

    .case-title2 {
      display: flex;
      align-items: center;
      padding-left: 20px;

      .case-title2_min {
        margin-left: 5px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
        line-height: 45px;
      }
    }

    .faultBtn {
      display: flex;
      flex-wrap: wrap;
      background: #f5f6fa;
      width: 100%;

      .faultBut_item {
        user-select: none;
        align-items: center;
        margin: 10px 25px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #0981ff;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        color: #0981ff;
        padding: 0 5px;
      }

      .faultBut_item:hover {
        height: 30px;
        background: #0981ff;
        font-size: 14px;
        border: 1px solid #0981ff;
        margin: 10px 25px;
        color: white;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
    }

    .AddDialog {
      .title {
        width: 232px;
        height: 12px;
        font-size: 12px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        color: #ff687b;
        margin-bottom: 20px;
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  /deep/.el-input-group__prepend {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }
}
.class_type {
  padding: 20px;

  .tip {
    font-size: 14px;
    color: #666666;
  }

  /deep/ .el-radio-group {
    .el-radio-button {
      margin-right: 20px;

      .el-radio-button__inner {
        border-radius: 4px;
        border: 1px solid #0981ff;
      }
    }
  }
}
</style>
  